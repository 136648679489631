import React from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faPhone, faIndustry } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import Metatags from "../components/metatags";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

import "../css/home.css"
import "../css/contact.css";

class Contato extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cNome: "",
      cEmail: "",
      cEmpresa: "",
      cTelefone: "",
      cMensagem: "",
      tNome: "",
      tEmail: "",
      tTelefone: "",
      tMensagem: "",
      cEmailError: false,
      tEmailError: false,
      cformEnabled: false,
      tformEnabled: false,
      messageModal: false,
    };
  }

  changeValue = async (evt) => {
    this.setState(
      {
        [evt.target.name]: evt.target.value,
      },
      () => {
        this.verifyEnable();
      }
    );
  };

  changeEmailValue = async (evt) => {
    evt.persist();
    this.setState(
      {
        [evt.target.name]: evt.target.value,
      },
      () => {
        this.verifyEmail(evt);
      }
    );
  };

  verifyEnable = () => {
    if ((!this.state.cEmailError || this.state.cEmail === "") && this.state.cMensagem !== "") {
      this.setState({
        cformEnabled: true,
      });
    } else {
      this.setState({
        cformEnabled: false,
      });
    }
  };

  verifyEmailString(email) {
    const re = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    );
    if (re.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  verifyEmail = (evt) => {
    let fieldEmail = evt.target.name + "Error";
    if (this.verifyEmailString(this.state[evt.target.name]) || this.state[evt.target.name] === "") {
      this.setState(
        {
          emailFocus: false,
          [fieldEmail]: false,
          formEnabled: true,
        },
        () => {
          this.verifyEnable();
        }
      );
    } else {
      this.setState(
        {
          emailFocus: false,
          [fieldEmail]: true,
          formEnabled: false,
        },
        () => {
          this.verifyEnable();
        }
      );
    }
  };

  sendMail = (fileName) => {
    let emailData;
      emailData = JSON.stringify({
        type: "ethical-chanel",
        name: this.state.cNome,
        email: this.state.cEmail,
        company: this.state.cEmpresa,
        phone: this.state.cTelefone,
        message: this.state.cMensagem,
        site: "Canal de conduta ética da MaxiPack"
      });
    var req = new XMLHttpRequest();
    req.open("POST", "https://fmohl6l9ek.execute-api.sa-east-1.amazonaws.com/DEV1", true);
    req.setRequestHeader("Content-Type", "application/json");
    req.addEventListener("load", function () {
      if (req.status < 400) {
        var response = JSON.parse(req.responseText);
        console.log(response.body);
        console.log(response.body.redirect);
        let { redirect } = response.body;
        console.log(typeof response);
        console.log(redirect);
        if (typeof window !== `undefined`) window.location.replace(window.origin + "/" + response.body.redirect);
      } else {
        console.log("Request failed: " + req.statusText);
      }
    });
    req.send(emailData);
    console.log(emailData);
  };


  submit = (evt) => {
    evt.preventDefault();
    this.sendMail();
  };

  render() {
    return (
      <>
        <Metatags
          title="Canal de conduta ética - Maxipack"
          description="Canal de conduta ética"
          url="https://canaldecondutaetica.maxipack.com.br/"
          image="https://canaldecondutaetica.maxipack.com.br/maxipack.png"
          keywords="terceirização, embalagens, manuseio, re-packer"
        />
        <Navbar />
        <div className="space-50" style={{ height: "50px" }} />
        <Container>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <Container>
                <Row style={{ fontSize: "40px" }}>
                  <Col>
                    <a href="https://wa.me/5519999897007" target="_blank" rel="noopener noreferrer">
                      <Button variant="primary" className={"shadow-none"}>
                        <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "40px" }} />
                      </Button>
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container>
                <Row id="contato">
                  <Col className="mb-lg-auto" lg="12">
                    <Form>
                      <Form.Group controlId="formBasicNome">
                        <Form.Label>
                          <FontAwesomeIcon icon={faUser} />
                          &nbsp;Nome
                        </Form.Label>
                        <Form.Control name="cNome" type="text" placeholder="nome" onChange={this.changeValue} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          <FontAwesomeIcon icon={faEnvelope} />
                          &nbsp;Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="email"
                          name="cEmail"
                          onChange={this.changeEmailValue}
                          onBlur={this.verifyEmail}
                          className={classnames({
                            "input-group-focus": this.state.emailFocus,
                            "input-group-error": this.state.cEmailError,
                          })}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmpresa">
                        <Form.Label>
                          <FontAwesomeIcon icon={faIndustry} />
                          &nbsp;Empresa
                        </Form.Label>
                        <Form.Control name="cEmpresa" type="text" placeholder="empresa" onChange={this.changeValue} />
                      </Form.Group>
                      <Form.Group controlId="formBasicTelefone">
                        <Form.Label>
                          <FontAwesomeIcon icon={faPhone} />
                          &nbsp;Telefone
                        </Form.Label>
                        <Form.Control name="cTelefone" type="text" placeholder="telefone" onChange={this.changeValue} />
                      </Form.Group>
                      <Form.Group controlId="formMenssagem">
                        <Form.Label>Mensagem</Form.Label>
                        <Form.Control
                          name="cMensagem"
                          as="textarea"
                          rows="3"
                          placeholder="mensagem"
                          onChange={this.changeValue}
                        />
                      </Form.Group>
                      <div style={{ height: "10px" }} />
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={!this.state.cformEnabled}
                        onClick={(e) => {
                          this.submit(e);
                        }}
                      >
                        Enviar
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <div style={{ height: "80px" }} />
        <Footer />
      </>
    );
  }
}

export default Contato;
